@import '../node_modules//bootstrap/dist/css/bootstrap.min.css';
@import './assets/css/custom.css';
/* @import './assets/css/dark-template.css';  */
/* @import './assets/css/dark-theme.css';  */
/* @import './assets/css/floater-template.css';  */
@import './assets/css/helpers.css';
/* @import './assets/css/horizontal-template.css';  */
@import './assets/css/legacy-template.css';
/* @import './assets/css/light-template.css'; */
/* @import './assets/css/light-theme.css'; */
/* @import './assets/css/vendor.styles.css'; */
/* @font-face {
 font-family: chivo;
 src: url(assets/font/Chivo/Chivo-Black.ttf);
} */

/*--------------------------------------------home page OwlCarousel-------------------------------------------------------------- */
.event-owl .owl-prev,
.event-owl .owl-next {
  padding: 0.5rem 1rem !important;
  width: 40px;
  height: 40px;
  border: 1px solid #404040 !important;
  border-radius: 0;
  margin: 0;
  outline: none !important;
}
.event-owl.owl-theme .owl-nav [class*='owl-'] {
  color: #fff;
  font-size: 14px;
  margin: 0px !important;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 0 !important ;
}

.event-owl.owl-theme .owl-nav [class*='owl-']:hover {
  background: red !important;
  color: #fff;
  text-decoration: none;
}
.home-form .form-control {
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 14px !important;
  border-width: 0 0 1px 0;
  background-color: transparent;
}
.rdtPicker {
  padding: 12px 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  margin-top: 0 !important;
  position: absolute;
  font-size: 0.875rem !important;
  box-shadow: 0px 0px 40px 0px rgb(79 61 101 / 15%) !important;
  border-radius: 0.25rem !important;
  background-color: #ffffff !important;
  min-width: 160px !important;
}
.rdtCounter .rdtCount {
  color: #3f3f3f;
  font-weight: 100;
  font-size: 14px !important;
}
.rdtCounterSeparator {
  line-height: 96px !important;
}
.rdtCounter .rdtBtn:hover {
  /* background: #eee; */

  border: none;
  border-radius: 3px !important;
}
.bs-stepper-content .string-check .string-check-label {
  min-height: 18px;
  margin-left: 1.375rem;
  display: inline-flex !important;
  margin-bottom: 0;
  font-size: 0.8125rem;
  line-height: 1.5;
}
.csm-check .string-check-label {
  display: inline-flex !important;
}
/* .autocomplete-dropdown-container {

    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    position: absolute;
    z-index: 1000;
    border-radius: 0 0 2px 2px;
    } */
i.fa.fa-times-circle {
  position: absolute;
  right: 7px !important;
  top: 29px;
  cursor: pointer;
}
.autocomplete-dropdown-container {
  z-index: 1000;
  padding: 0px 2px 1px;
}

@font-face {
  font-family: chivoBold;
  src: url(assets/font/chivoBold/Chivo-Bold.ttf);
}
@font-face {
  font-family: chivo;
  src: url(assets/font/Chivo/Chivo-Regular.ttf);
}
/* 

body{
  color: #404040 !important;
 font-family: 'Chivo', sans-serif !important;
} */
.bs-stepper-header {
  display: flex;
  margin-right: -5px;
  margin-left: -4px;
  align-items: center;
}
/* .step.col-lg-3.col-sm-6.mb-3.d-flex.justify-content-center.active {
  background: #000;
  color: #fff;
  
} */
.step.col-lg-3.col-sm-6.mb-3.d-flex.justify-content-center.active
  .step-trigger {
  width: 100%;
  background: #000 !important ;
  color: #fff !important;
}

.bs-stepper .step-trigger:hover {
  width: 100%;
  color: #fff !important;
  background: red !important;
}
.bs-stepper .step-trigger:focus {
  color: #000 !important;
}
.bs-stepper .step-trigger {
  width: 100%;
  letter-spacing: 0.3px;
  font-size: 13px !important;
  border-radius: 0 !important;
  padding: 14px 0 !important;
  color: #000 !important;
  background: #e9edf3 !important;
}
button.btn.btn-primary.mr-2.previous {
  /* background: red; */
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}
button.btn.btn-primary.mr-2.previous:hover {
  color: #212529;
  background-color: #bbc5d8;
  border-color: #b2bfd4;
}
/* button.btn.btn-primary.next:hover{
  border-radius: 5px;
} */
i.fa.fa-plus {
  font-size: 9px !important;
}
.ant-btn-primary {
  color: #fff;
  background: #ef3e27;
  border-color: #ef3e27;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:hover {
  color: #fff;
  background: #000;
  border-color: #000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.bs-stepper-content {
  color: #3f3f3f;
  background: #ffffff;
  display: block;
  margin: 0.5em;
  min-height: 24em;

  overflow: hidden;
  position: relative;
  width: auto;
  padding: 30px !important;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px !important;
  border: 1px solid #e6e4e9;
}
.fa-home {
  font-size: 18px;
}
.item {
  width: 448px;
  margin-right: 20px;
}
.navbar-container {
  transition: all 0.3s;
  min-height: calc(100vh - 3.75rem);
  padding: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.ant-picker.form-control.home-datepicker.ant-picker-focused {
  border-right: 0 !important;
}
.cm_check {
  width: 50px;
  height: 50px;
  background: #ef3e27;
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
}
.cm_check:hover {
  background: #000;
}
.btn-primary:focus,
.wizard > .actions a:focus,
.btn-primary.focus,
.wizard > .actions a.focus {
  box-shadow: 0 !important;
}

.cm_check:before {
  content: '';
  width: 16px;
  height: 25px;
  border-right: 3px solid #000;
  border-bottom: 3px solid #000;
  position: absolute;
  top: 9px;
  transform: rotate(45deg);
  left: 16px;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #000 !important;
  border-color: #000 !important;
  box-shadow: 0 !important;
}

.cm_check:hover::before {
  content: '';
  width: 16px;
  height: 25px;
  border-right: 3px solid #ef3e27;
  border-bottom: 3px solid #ef3e27;
  position: absolute;
  top: 9px;
  transform: rotate(45deg);
  left: 16px;
}
.eye_icon {
  position: absolute;
  width: 22px;
  top: 12px;
  right: 15px;
  cursor: pointer;
  z-index: 99;
}

.eye_icon svg {
  fill: #ced4da;
}
.form-group.inner-addon {
  position: relative;
}

.eye_icon.slesh:after {
  content: '';
  position: absolute;
  width: 26px;
  height: 3px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  transform: rotate(-45deg);
  border-radius: 3px;
}
.error {
  color: red;
  text-align: left;
  margin-left: 12px;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 50%;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #000;
  content: '';
  transition: width 0.2s;
}

.underlineHover:hover:after {
  width: 100%;
}

button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary,
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary:focus {
  background: none !important;

  border: none !important;
}
svg.svg-inline--fa.fa-w-16.far.fa-user-circle.fa-2x {
  height: 32px;
}

.delete-content .cm_modal {
  padding: 10px 15px;
}
.delete-content {
  width: 30% !important;
  border-radius: 2px;
}

@media (max-width: 767px) {
  .delete-content {
    width: 90% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .delete-content {
    width: 50% !important;
  }
}
/* .dropdown-menu.nav-user-container.animated.dropdown-menu.show {
 left: -20px !important;
} */
@media (min-width: 1200px) {
  .container-fluid {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* .card-body.card {
 max-width: 497px !important;
 width: auto;

} */
.fb-btn {
  display: flex;
  width: 100% !important;
}
.reset-card {
  width: 30% !important;
  padding: 20px;
}

@media (max-width: 1600px) {
  .reset-card {
    width: 50% !important;
    padding: 20px;
  }
}
@media (max-width: 700px) {
  .reset-card {
    width: 90% !important;
    padding: 20px;
  }
}

.main-panel {
  transition: all 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 3.75rem);
  /* position: relative !important; */
  top: 3.75rem;
  right: 0;
  display: flex;
  flex-direction: column;
  padding-left: 270px;
}
.white-footer {
  background: #ffffff;
  color: #ababbb;
  padding: 1.5rem;
  font-size: calc(0.875rem - 0.05rem);
  font-weight: 400;
}

/*  */

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar ul li {
  position: relative;
  padding: 10px;
  padding-left: 50px;
}
.sidebar {
  position: fixed;
  background: #231f20;
  height: 100%;
  width: 270px;
  left: 0;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  transition: all 0.3s;
  min-height: calc(100vh - 3.75rem);
  padding: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;

  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}

.cm_close {
  width: 21px;
  right: 20px;
  cursor: pointer;
  position: absolute;
  color: #fff;
  font-size: 24px;
  top: 16px;
}
img.logo-white {
  height: calc(6rem - 1.5rem) !important;
  margin-left: 22%;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .navbar .navbar-menu-container .navbar-nav .nav-item .nav-link {
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem -0.875rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 35px;
    margin-left: -13px;
    text-align: center;
    border-radius: 100%;
    transition: all 0.2s;
  }
  .page-title {
    display: none !important;
  }
  .menu_open .sidebar {
    left: 0px !important;
  }
  .sidebar {
    left: -330px;
    z-index: 999;
    top: 0px;
    max-height: (100vh - 75px);
    transition: all 0.3s;
  }
  .second-nav {
    display: block;
    padding: 0 0 0 50px;
    height: 80px;
    z-index: 12;
    position: relative;
    width: 100%;
    background-color: #fff;
  }
  .active {
    /* display: block;
    padding: 0 0 0 50px;
    height: 80px;
    z-index: 12; */
    right: 0;
  }
  .show.search {
    display: block;
    padding: 0 0 0 50px;
    height: 80px;
    z-index: 12;
    position: relative;
    width: 100%;
    background-color: #fff;
  }
  /* nav#mobile-navbar {
 transition: all .3s;
 min-height: 100vh;
 padding: 0;
 position: fixed;
 overflow: hidden;
 top: 0;
 bottom: 0;
 right: -300px;
 z-index: 999;
} */

  .mobile_trigger {
    position: absolute;
    right: 0;
    display: block;
    z-index: 99;
    cursor: pointer;
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem 0.125rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 50%;
    transition: all 0.2s;
  }
  .mobile_trigger:hover {
    color: #ef3e27;
  }

  .mobile_trigger span {
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem 0.125rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 50%;
    transition: all 0.2s;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
  }
}

.nav-item.nav-profile {
  align-self: stretch !important;
  margin-right: 25px;
}
@media (min-width: 1331px) {
  .cm_close,
  .mobile_trigger {
    display: none !important;
  }
}
.navbar .navbar-menu-container .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem -0.875rem;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  transition: all 0.2s;
}

img.logo-white {
  height: calc(6rem - 1.5rem) !important;
  margin-left: 28%;
  margin-top: 15px;
  margin-bottom: 24px !important;
}
.sidebar-menu a {
  color: #fff;
  display: inline-block;
  font-size: 0.8125rem;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
}
.sidebar-menu li:hover {
  color: #ef3e27 !important;
}
.sidebar-menu a:hover,
.sidebar-menu a:active {
  color: #ef3e27 !important;
  text-decoration: none;
}

@media only screen and (max-width: 1025px) {
  .second-nav1 {
    right: 0;
  }
}

i.fa.fa-facebook-official.mr-1 {
  font-size: 17px !important;
}

.error {
  color: red;
  text-align: left;
  margin-top: 10px !important;
  margin-left: 12px;
}
/* .content-home {
  margin: 31px 230px !important;
 
} */
content-wrapper-home {
  /* position: relative; */
  /* overflow: hidden; */
  /* height: 126%; */
}
.event-form-home.card {
  bottom: -50px !important;
}
/* .d-block {
  position: absolute;
  top: 31%;
} */

.page-title {
  text-transform: uppercase;
  font-weight: 500 !important;
  font-family: chivoBold;
  color: #3f3f3f !important;
  width: 270px !important;
  padding-left: 1.875rem !important;
}
.card .card-header .card-title {
  color: #3f3f3f;
  margin-bottom: 0;
  font-weight: 500 !important;
  font-family: chivoBold !important;
  text-transform: uppercase;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}

.modal-header {
  border: 0 !important;
}
form.home-form {
  padding: 0.7rem 0.5rem;
}
/* .card-body {
  padding: 1.25rem !important;
} */
.modal .modal-dialog .modal-content .modal-header .close span {
  font-size: 28px;
  font-weight: 400;
  color: #333 !important;
}
button.btn.btn-brand.form {
  padding: 19px 25px;
  position: absolute;
  right: 0;
  background-color: #ef3e27;
  color: #fff;
  border-radius: 0px;
  bottom: 0;
}
.btn i,
.wizard > .actions a i,
.fc button i {
  font-size: 1.25rem !important;
}
label.m-0.text-capitalize.font-size-12 {
  color: #737373 !important;
  font-size: 12px !important;
}
p.text-white.font-size-16 {
  line-height: 1.5 !important;
  margin-bottom: 1rem;
}
h1.text-uppercase.text-white.font-weight-bold {
  font-size: 2.5rem;
  font-weight: bold !important;
  letter-spacing: 1px !important;
}

/* loader */

.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*,......................................event pages...........................................  */

.event .event-banner {
  position: relative;
  margin-top: 5rem;
  border-radius: 20px;
  height: 530px;
  z-index: 22;
  overflow: hidden;

  background: no-repeat center center;

  background-size: cover;
}
.event .event-banner a:hover {
  color: #fff;
}
/* .event .event-banner img{
width: 100%;
height: 100%;
position:absolute;
left: 0;
top: 0;
} */
.event-banner-mb .shadow,
.event .event-banner .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-box-shadow: inset 0 -90px 100px #000000;
  -webkit-box-shadow: inset 0 -90px 100px #000000;
  box-shadow: inset 0 -90px 100px #000000 !important  ;
  border-radius: 20px;
  top: 0;
  left: 0;
}
.event .event-banner .event-title {
  padding: 2rem;
  position: relative;
  z-index: 111;
}
.event .event-banner .event-price-container {
  height: 70px;
  position: relative;
  z-index: 111;
}
.event .event-banner .event-price-container a:hover {
  background-color: #e1250d;
  transition: all 0.3s;
}
.event .event-banner .event-price-container .event-price {
  background-color: #ebebeb;
  padding: 1.2rem;
}
.event-banner-mb {
  /* background: url("assets/images/safe.jpg") no-repeat !important; */
  height: 450px;
  border-radius: 20px;
  z-index: 1;
  position: relative;
  -webkit-box-shadow: 0px 0px 43px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 43px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 43px -10px rgba(0, 0, 0, 0.75);
}
.event-banner-mb .event-title {
  margin: 2rem;
  position: relative;
  z-index: 111;
}
.event-banner-mb .event-price-container {
  height: 70px;
  position: relative;
  z-index: 111;
}
.event-banner-mb .event-price-container .event-price {
  background-color: #ebebeb;
  padding: 1.2rem;
}
.event .event-info {
  margin-left: 2rem;
}
.social-anchor a:hover {
  background-color: #ef3e27;
  color: #fff !important;
  transition: all 0.3s;
}
.buy-tickets .btn:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
}
.feed .feed-body .user-img {
  height: 70px;
  width: 70px;
}
.feed .feed-footer .action-link.active {
  text-decoration: underline !important;
}
.feed .feed-footer .action-link {
  text-decoration: none;
  color: #404040;
}
.feed .feed-footer .action-link:hover {
  color: #ef3e27;
  transition: all 0.3s;
}
.feed-comment .user-img {
  height: 50px;
  width: 50px;
}
.feed-comment .comment-panel {
  background-color: #f4f4f4;
}
.event-location img {
  width: 100%;
}
.event-address {
  position: relative;
  top: -50px;
}
.event-address-container {
  padding: 0 230px;
  background: linear-gradient(180deg, #f4f4f4 67%, #ffffff 67%);
}
.promoter-img {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}
.related-events {
  margin-bottom: 4rem;
}
.related-events .item {
  height: 400px;
}
.related-events .item .event-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  -moz-box-shadow: inset 0 -250px 700px #000000;
  -webkit-box-shadow: inset 0 -250px 700px #000000;
  box-shadow: inset 0 -250px 700px #000000;
}
.related-events .item .event-link {
  text-decoration: none;
  color: #fff;
}
.related-events .item .event-link:hover,
.related-events .item .event-body a:hover {
  color: #ef3e27;
  transition: all 0.3s;
}

.related-events .item .event-body p {
  word-wrap: break-word;
  width: 100%;
}
.related-events .item .event-body .action-link {
  color: white;
  text-decoration: none;
}
.event-img-gallery {
  display: flex;
  flex-wrap: wrap;
}
.event-img-gallery a {
  width: 33.33%;
  padding: 2px;
}
.event-img-gallery img {
  width: 100%;
}
.event-img-gallery img:hover {
  opacity: 1;
}

@media only screen and (max-width: 1025px) {
  .event-address-container {
    padding: 0 100px;
  }
}

@media (max-width: 575.98px) {
  body.event-bdy {
    background: none;
  }
  .overlay-bg {
    display: none;
  }
  .event .event-banner .event-title {
    margin: 0 2rem;
  }
  .event .event-banner {
    margin: 0;
    position: absolute;
    left: 0;
    z-index: 1;
  }
  .event .event-info {
    margin-left: 0;
  }
  .event-address-container {
    padding: 0;
  }
}

body.event-bdy {
  /* background: url('../images/event-list.jpg') no-repeat; */
  background-size: 100% 630px;
}
body.event-list-bdy {
  /* background: url('../images/event-list.jpg') no-repeat; */
  background-size: 100% 630px;
  background-color: #1c1c1c;
}
.overlay-bg {
  position: absolute;
  width: 100%;
  height: 630px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
/* body{
  background: linear-gradient(180deg, #f4f4f4 41.4%, #ffffff 41.4%);
  font-family: 'Chivo', sans-serif;
  letter-spacing: .3px;
  color: #404040;
  font-size: 0.875rem;
} */
.base-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.content-wrapper {
  overflow: hidden;
  min-height: 100vh;
}

.navbar-mb-menu-container {
  z-index: 12;
  position: relative;
  width: 100%;
  height: 80px;
}
.navbar-mb-more {
  position: absolute;
  top: -1000px;
  width: 280px;
  z-index: 1;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.navbar-mb-more.show {
  top: 80px;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  right: 0;
}

/* .navbar .logo-container{
  position: absolute;
  z-index: 12;
  top: 0;
  width: 180px;
  height: 120px;
  background-color: #ef3e27;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .logo-container img{
  position: relative;
  width: 80%;
}
.navbar .navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  line-height: 80px;
}
.navbar .navbar-nav > .nav-item form{
  height: 80px;
}
.navbar .navbar-nav > .nav-item input{
  color: #808080;
  border: none;
  padding: 0;
  font-size: 14px;
  box-shadow: none;
}
.navbar .navbar-nav > .nav-item a{
  padding: 0 30px;
  font-family: "GT Eesti Pro Display Light", sans-serif;
  color: #808080;
  font-size: 14px;

}
.navbar .navbar-nav > .nav-item a:hover{
  background-color: #ef3e27;
  color: #FFF;
  transition: all .2s;
}
.navbar .navbar-nav > .nav-item a.text-brand:hover{
  background-color: #ef3e27;
  color: #FFF !important;
  transition: all .2s;
}
.navbar .navbar-nav .nav-item button{
  color: #434343;
}
.navbar .navbar-nav > .nav-item.nav-user{
  background-color: #ef3e27;
  color: #FFF;
}
.navbar .navbar-nav > .nav-item.nav-user a{
  font-weight: 700;
  font-family: 'Chivo', sans-serif;
}
.navbar .navbar-nav > .nav-item a.general{
  font-family: 'Chivo', sans-serif;
  color: #404040;
  padding: 0;
  margin: 0 30px;
  line-height: 78px;
}
.navbar .navbar-nav > .nav-item a.general:hover{
  background-color: #FFF;
}
.navbar .navbar-nav > .nav-item a.general.active{
  background-color: #FFF;
}
.navbar .navbar-nav > .nav-item a.general::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background-color: #ef3e27 !important;
  transition: width .3s;
}
.navbar .navbar-nav > .nav-item a.general:hover::after, .navbar .navbar-nav > .nav-item a.general.active::after {
  width: 100%;
} */
.banner-front {
  background-color: #000;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.banner-front form {
  width: 500px;
}
.banner-front button {
  position: absolute;
  left: 0;
  padding: 0;
  bottom: 1rem;
}
.banner-front .form-control:focus {
  color: #808080;
}
.banner-front input {
  padding: 1rem 2rem;
  color: #808080;
}
.accordion .card-header {
  padding: 1.1rem 0;
}
.accordion .card-header .card-title {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: #404040;
}
.accordion .card-header:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f068';
  float: right;
  color: #ef3e27;
}
.accordion .card-header.collapsed:after {
  content: '\f067';
}
.success-content {
  margin: 0 400px;
  min-height: calc(100vh - 80px - 320px);
}
.success-content .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}
.content-event {
  margin: 0 230px;
  min-height: calc(100vh - 80px - 320px);
}
.content .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}

.card {
  border-radius: 0px;
}
.mb-cart-info {
  display: flex;
  align-items: center;
  position: relative;
  height: 80px;
  -webkit-box-shadow: 0px -6px 34px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -6px 34px -14px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -6px 34px -14px rgba(0, 0, 0, 0.75);
}
.mb-cart-info .cart,
.mb-cart-info .checkout {
  padding: 1.25rem;
}
.mb-cart-info .cart .cart-toggle::after {
  content: none;
}
.mb-cart-info .cart .count {
  position: absolute;
  top: 12px;
  left: 35px;
}
.mb-cart-info .cart .cart-container {
  top: -375px !important;
  border-radius: 0;
  width: 300px;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
/* .navbar-mb-container {
  transition: all .3s;
  min-height: 100vh;
  padding: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: -300px;
  z-index: 999;
}
.navbar-mb-container .secondary{
  background: #FFF;
  width: 250px;
}
.navbar-mb-container .secondary .nav-wrapper {
  height: 100vh;
  position: relative;
  overflow: auto;
  padding-top: 3em;
}
.navbar-mb-container .secondary .nav-wrapper .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item{
  transition: all .3s;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item .nav-link{
  display: flex;
  white-space: nowrap;
  padding: 0.5625rem 0.875rem;
  color: #ef3e27;
  transition: all .3s;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item .nav-link.active{
  background-color: #ef3e27;
  color: #FFF;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item.collapse {
  z-index: 999;
} */
.sidebar-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.sidebar-overlay.active {
  display: block;
  opacity: 0.1;
}
.footer {
  background-color: #000;
  padding: 0 230px;
  bottom: 0;
  position: relative;
  width: 100%;
}

.footer .footer-body {
  padding: 40px 0;
  border-bottom: 2px solid #1c1c1c;
}
.footer .footer-body ul li a:hover,
.footer .footer-privacy a:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
}
.footer .footer-body ul li a {
  color: #aeaeae;
  text-decoration: none;
  line-height: 1.7;
}

.footer .footer-privacy span {
  color: #aeaeae;
}
.footer .footer-privacy i {
  font-size: 14px;
}
.footer-support {
  background-color: #f4f4f4;
  padding: 3rem;
}

@media only screen and (max-width: 1025px) {
  /* body {
      background: linear-gradient(180deg, #f4f4f4 25%, #ffffff 25%);
  } */
  /* .navbar .logo-container{
      width: 160px;
      height: 100px;
  } */
  .navbar .navbar-nav .nav-item {
    margin-right: 20px;
  }
  .success-content,
  .content {
    margin: 0 100px;
  }
  .mb-cart-info {
    padding: 0 100px;
  }
  .mb-cart-info .cart {
    padding-left: 0;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 114px;
  }
  .navbar-mb-container.active {
    right: 0;
  }
  .footer {
    padding: 0 100px;
  }
}

/* Extra small devices Portrait */
@media (max-width: 575.98px) {
  /* .navbar{
      padding: 0 0 0 1.25rem;
      height: 60px;
  } */
  /* .navbar .logo-container {
      width: 100px;
      height: 70px;
  }
  .navbar .navbar-nav{
      line-height: 60px;
  }
  .navbar .navbar-nav .nav-item{
      margin-right: 15px;
  }
  .navbar .navbar-nav .nav-item button{
      font-size: 15px;
  }
  .navbar-mb-menu-container{
      height: 60px;
  }
  .navbar-mb-more.show {
      top: 60px;
  } */
  .mb-cart-info {
    padding: 1.25rem;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 35px;
  }
  .success-content,
  .content {
    margin: 0 1.25rem;
  }
  .footer {
    padding: 0 1.25rem;
  }
  .banner-front {
    padding: 1rem;
    text-align: center;
  }
  .banner-front form {
    width: 90%;
  }
}
label {
  color: #737373;
  font-size: 15px;
}

.event .event-banner {
  position: relative;
  /* background: url("assets/images/safe.jpg") no-repeat !important; */
  margin-top: 5rem;
  height: 530px;
  z-index: 22;
  overflow: hidden;
  background-size: 100% !important;
}
a.link:hover {
  color: black;
}
h2.text-uppercase.text-white.font-weight-bold.mb-0 {
  font-size: 2rem;
}
h3.text-uppercase.border-bottom {
  color: #404040;
  font-family: chivofont;
  font-weight: 100 !important;
  font-size: 1.75rem;
}
p {
  line-height: 1.5 !important;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

a.btn.btn-outline-brand.border-brand {
  border-radius: 0px;
  color: #ef3e27;
  border-color: #dddddd;
  background-color: #fff;
}
text-brand {
  color: #ef3e27 !important;
}
.border-brand {
  border-color: #ef3e27 !important;
}
.btn-outline-brand:hover {
  color: #fff !important;
  background-color: #ef3e27 !important;
}

h4.font-weight-bold.text-capitalize {
  font-size: 1.5rem;
  color: #404040;
}
button.btn-lg.btn-brand {
  font-size: 1.25rem;
}

.border-brand {
  color: #ef3e27 !important;
  border-color: #ef3e27 !important;
}

.border-3 {
  border-width: 3px !important;
}

i.fas.fa-plus {
  line-height: 1rem !important;
  font-size: 1rem !important;
  margin-right: 3px;
}
button.btn.btn-outline-brand.bg-transparent.border-brand.border-3.pl-4.pr-4 {
  font-size: 15px;
  font-weight: 100;
}
button.btn.btn-brand.pl-3.pr-3.mr-1 {
  font-size: 15px;
  font-weight: 100;
}
.item.evnt-details-owl {
  background: no-repeat;
  background-size: 100% 100% !important;
}
.item.evnt-details-owl {
  width: 100%;
}
h4.text-capitalize.font-weight-bold.event-link-text {
  color: #fff !important;
}
h4.text-capitalize.font-weight-bold.event-link-text:hover {
  color: #ef3e27 !important;
}
h3.text-uppercase.font-weight-bold.pt-4.pb-2.mb-3.text-center {
  color: #404040;
  font-family: chivofont;
  font-weight: 100 !important;
  font-size: 1.75rem;
}

.event-detail {
  background: linear-gradient(180deg, #f4f4f4 41.4%, #ffffff 41.4%);
  font-family: 'Chivo', sans-serif;
  letter-spacing: 0.3px;
  color: #404040;
  font-size: 0.875rem;
}

i.fa.fa-plus.add-input {
  font-size: 13px !important;
  margin-right: 9px;
}
i.fas.fa-minus.remove-input {
  font-size: 12px !important;
  margin-right: 4px;
}

button.nav-link-home.navbar-toggler.navbar-toggler-right.align-self-center {
  color: #fff !important;
}

/* .home-dropdown .dropdown-toggle::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
} */

/* button.home-dropdown.dropdown-toggle.btn.btn-primary {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: #fff !important;
  padding: 0 30px !important;
  font-size: 20px !important;
} */
img.widget-8-img {
  width: 92px !important;
  height: 42px !important;
}

.content-wrapper.manage-event {
  min-height: 80vh !important;
}
i.fa.fa-pencil-square-o.toolbar-icon {
  font-size: 15px !important;
  font-weight: 100 !important;
}
.navbar-home .navbar-nav > .nav-item.nav-user a {
  padding: 30px !important;
  /* font-weight: 700; */
  font-family: 'Chivo', sans-serif;
}
ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  right: auto;
  bottom: auto;
  transform: translate(-97px, -5px) !important;
  width: 200px !important;
}
svg.svg-inline--fa.fa-w-16.far.fa-user-circle.fa-2x.login-home {
  color: #fff;
  font-size: 46px !important;
}
.link-home {
  text-decoration: none !important;
}
.link-home:hover {
  color: #ef3e27 !important;
  text-decoration: none !important;
}

.menu-item:hover {
  color: #ef3e27 !important;
  text-decoration: none !important;
}
.ant-picker.form-control.home-datepicker {
  border-right: 0 !important;
}
i.fa.fa-minus {
  font-size: 10px !important;
}
@media (max-width: 575.98px) {
  .navbar {
    padding: 0 0 0 0rem;
    height: 60px;
  }
}
/* nav.navbar.fixed-top {
  padding: 0 !important;
} */
.navbar-nav.navbar-nav-right {
  outline: none !important;
}
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary {
  outline: none !important;
}
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary,
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary:focus {
  background: none !important;
  outline: none;
  box-shadow: none !important;
  border: none !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #ef3e27 !important;
  border: #ef3e27 !important;
  outline: #ef3e27 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #ef3e27 !important;
  border-radius: 2px;
  content: '';
}

.ant-picker-cell-inner:hover {
  background: #ffdcda !important;
}

.ant-picker-header-view button:hover {
  color: #ef3e27 !important;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #ef3e27;
  box-shadow: none !important;
  border-right-width: 1px !important;
}

.event-list .event-item .event-schedule .event-day {
  font-size: 70px;
  font-weight: 100;

  font-family: chivoLight !important ;
}
.event-list .event-item .event-schedule .event-month-time span {
  font-weight: 100;

  font-family: chivoLight !important ;
}
a.active {
  color: #ef3e27 !important;
}
.pagination li a {
  text-decoration: none;
  position: relative;
  display: block;
  border-radius: 2px !important;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  margin: 3px;
  line-height: 1.25;
  color: #404040;

  border: 0px solid #ef3e27;
}
.pagination {
  list-style: none;
}

.pagination li.disabled a {
  cursor: not-allowed;
  z-index: 1;
  color: rgba(0, 0, 0, 0.25);

  border-radius: 0;
}
.pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}
.pagination li:hover a {
  z-index: 1;
  color: #fff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

p.mb-3.card-p {
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
p.mb-3.card-p {
  height: 109px;
}
p.card-p {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
i.fa.fa-sort {
  cursor: pointer;
}

.home-form .form-control {
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 14px !important;
  border-width: 0 0 1px 0;
  background-color: transparent;
}

.select-limit span.ant-select-arrow {
  color: #000;
  font-weight: bold;
  font-family: chivoBold;
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: none !important;
  cursor: default;
  color: #404040 !important;
}
.ant-select-disabled span.ant-select-arrow {
  display: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 !important;
}
.ant-select {
  box-shadow: none !important;
}
/* .ant-select-selection:focus , .ant-select-selection:active{
  color: #495057 !important;
  background-color: #fff !important;
  border-color:red !important;
  outline: 0 !important;
  border-radius: 10px;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
}
.ant-select-selection:hover{
color: #495057 !important;
  background-color: #fff !important;
  border-color: red !important;
  outline: 0 !important;
  border-radius: 10px;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
}
 .ant-select-selection.ant-select-selection--single {
  border: none !important;
} */

li.page-change a {
  background-color: #ececec;
  border-radius: 2px !important;
}
li.page-change.active a {
  background-color: #ececec;
  border-radius: 2px !important;
}

li.page-change:hover a {
  background-color: #ef3e27;
}
.event-lable-ckeck {
  color: #3f3f3f;
}
span.tab-head {
  font-family: ChivoLight !important;
  font-size: 12px;
}
button.btn.btn-primary.filter {
  padding: 7px 12px !important;
  font-size: 12px !important;
  letter-spacing: 0.1px;
}
.card .card-header .card-title .card-title-secondary {
  color: #8d8a89;
  /* font-family: chivoLight !important; */
  padding-left: 0.5rem;
  font-weight: 300;
}
.fa.fa-heart {
  color: red !important;
}
.fa.fa-comment.comment-icon {
  color: #00cb8e !important;
}
i.fa.fa-user.follower {
  color: #ffc555 !important;
}
.ant-tabs-nav {
  left: 20px;
  top: 10px;
  color: #000 !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
  color: #ef3e27 !important;
  font-weight: 500;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #ef3e27 !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated:hover {
  background: #ef3e27 !important;
}
.ant-tabs-tab:hover {
  color: #ef3e27;
}
.mini_loader {
  width: 40px;
  height: 40px;
  border: 2px #347bde solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
  position: absolute;
  left: 75px;
  bottom: -8px;
}
.mini_loader:after {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;
  content: '';
}
/*  */
.autocomplete-dropdown-container {
  z-index: 1000;
  padding: 0px 2px 1px;
}
/* .location-search-input,
.location-search-input:focus,
.location-search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  line-height: 1em;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
} */

/* .autocomplete-dropdown-container {

  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  position: absolute;
  z-index: 1000;
  border-radius: 0 0 2px 2px;
} */
i.fa.fa-times-circle {
  position: absolute;
  right: 7px !important;
  top: 29px;
  cursor: pointer;
}
.upload_doc {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  min-height: 314px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #777;
  background-color: #fff;
  background-image: none;
  text-align: center;
  border: 2px solid #e5e5e5;
  -webkit-transition: border-color 0.15s linear;
  transition: border-color 0.15s linear;
}

.upload_doc p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  /* font-size: 18px;
  font-weight: bold;
  word-break: break-all; */
}

.ant-select.form-control-select.ant-select-single.ant-select-show-arrow {
  width: 100%;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .form-control-select {
  height: 39px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 39px !important;
  padding: 0 11px;
}
.ant-select-selector {
  outline: none !important;
  box-shadow: none !important;
}
.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ef3e27 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ef3e27 !important;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ef3e27 !important;

  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 !important;
  box-shadow: 0 !important;
}
i.fa.fa-trash-alt {
  cursor: pointer;
}

.status_button.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #ef3e27 !important;
  border: 1px solid #ef3e27;
  border-radius: 0px;
  color: #fff !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-weight: bold !important;
  font-size: 12px !important;
  height: 33px !important;
  padding: 0 11px;
}
.status_button.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: 0px !important;
}
.status_button span.ant-select-arrow {
  display: none;
}
.status_button.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0px !important;
}
.ant-select-item:hover {
  color: #ef3e27 !important;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
  color: #ef3e27 !important;
  font-weight: 100 !important;
  background-color: none !important;
  background: none !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #ef3e27 !important;
  font-weight: 100 !important;
  background-color: #ececec !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: 3px !important;
}
.pagination li.disabled a {
  cursor: not-allowed;
  z-index: 1;
  color: #404040;
  border-radius: 0;
}
.dropdown-arrow {
  font-size: 11px !important;
}
i.fa.fa-circle {
  position: absolute;
  top: 16px;
  left: -5px;

  font-size: 4px !important;
}

li.sub-menu {
  font-size: 13px;
  font-weight: 100;
  text-transform: none;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #8f8c8b;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker.ant-picker-range {
  width: 100%;
  height: 39px;
}
.rang-picker .ant-picker-suffix {
  position: absolute;
  background: #f5f5f7;
  padding: 7px 10px;
  right: 0;
  border-left: 1px solid #e6e4e9 !important;
  padding-left: 12px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #ef3e27;
  border-bottom: 1px dashed #ef3e27;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}
.fas.fa-cloud-upload-alt {
  font-size: 43px;
  position: absolute;
  left: 50%;
  /* top: -71%; */
  top: 0;
  transform: translate(-27%, -96%);
  margin: 0;
}
i.fa.fa-angle-double-down {
  margin-left: 9px;
  font-size: 14px !important;
}
.invoice .invoice-header {
  display: flex;
  padding: 4rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ef3e27;
}
.invoice .invoice-body table thead th {
  border-top: 0;
  font-weight: 500;
  font-size: 1rem;
  color: #ef3e27;
  border-color: #ef3e27;
  text-transform: uppercase;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #ffdcda !important;
}

.menu_icon {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 7px;
  border-radius: 7px;
  line-height: 25px;
  left: 29px;
  top: 0;
}
.sidebar ul li {
  position: relative;
  padding: 10px;

  padding-left: 68px;
}
li.sub-menu {
  padding-left: 10px !important;
}
i.fa.fa-home.menu-icon {
  position: absolute;
  left: 3px;
}
.menu_icon {
  font-size: 15px !important;
}
i.fa.fa-receipt {
  font-size: 17px !important;
}
i.far.fa-user {
  font-size: 16px;
}
button.btn.btn-light {
  margin-right: 4px;
}
.login_wrapper {
  height: 100vh;
  min-height: 630px;
}
.bg_white {
  background: #fff;
  border-radius: 20px;
  padding: 50px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  min-height: 500px;
}
@media (max-width: 1023px) {
  .bg_white {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
    min-height: 400px;
  }
}
.page_content {
  margin: 0 auto;
  max-width: 511px;
}
h4.cm_heading {
  font-size: 5rem;
}
/* .logo-white {
  display: block;
  width: auto;
  margin: 65px auto;
} */
.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cm_col {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 250px;
}
.ant-select.cm-select-box.ant-select-single.ant-select-show-arrow.ant-select-open {
  width: 100%;
}
.ant-select.cm-select-box.ant-select-single.ant-select-show-arrow {
  width: 100%;
}

p.event-content.dashboar {
  word-break: break-all;
}
h5.text-white.font-weight-bold.mb-3 {
  word-break: break-all;
}
span.search {
  position: absolute;
  right: 215px;
  top: 29px;
  /* margin-right: 5px; */
  /* left: 257px; */
}
input.form-control.search-box {
  height: 35px;
  margin-top: 20px;
  width: 202px;
}

.ant-time-picker-input::placeholder,
.ant-calendar-range-picker-input::placeholder,
.ant-time-picker-input::placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}
.ant-time-picker-input:-ms-input-placeholder,
.ant-time-picker-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000ad;
}
.selectHeight {
  height: auto !important;
}
.ant-time-picker-input::-ms-input-placeholder,
.ant-time-picker-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000ad;
}

.ant-calendar-input::placeholder,
.ant-calendar-picker-input::placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}
.ant-calendar-picker-input:-ms-input-placeholder,
.ant-calendar-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000ad;
}

.ant-calendar-picker-input::-ms-input-placeholder,
.ant-calendar-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000ad;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  font-size: 17px;

  color: rgba(0, 0, 0, 0.59) !important;
}
.ant-select-selection__placeholder {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.68) !important;
}

.ant-picker-input::placeholder,
.ant-picker-input > input::placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  opacity: 1; /* Firefox */
}
.home-datepicker .ant-picker-input::placeholder,
.home-datepicker .ant-picker-input > input::placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
  opacity: 1; /* Firefox */
}
.select-place::placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
}
.widget-status-desc {
  width: 814px;
  display: flex;
  align-items: center;
}

span.btn.btn-back.btn-outline-brand.border-brand.remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  color: #737373 !important;
}
span.btn.btn-back.btn-outline-brand.border-brand.remove-btn:hover {
  background: none !important;
}
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  top: -5px !important;
  max-width: 100%;
}

.mange-evnt {
  font-weight: 200 !important;
  font-family: chivoBold !important;
}

h3.text-white.text-uppercase.font-weight-bold.pb-3.event-list-title {
  font-size: 1.75rem !important;
}

.event-body .event-list .event-owl.owl-carousel .event-card .event-date {
  position: absolute;
  font-size: 1rem !important;
  /* font-family:  */
  font-family: chivoBold !important;
  background-color: white;
  font-weight: 500 !important;
  width: 70px;
  height: 70px;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
}

.related-events .item .event-date {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ef3e27;
  color: white;
  width: 80px;
  position: absolute;
  padding: 0.4rem 1rem;
  top: 0;
  left: 0;
  font-size: 20px;
  text-transform: uppercase;
}
/* .table thead th, .jsgrid .jsgrid-table thead th {
  font-weight: bold;
  font-size: 11px;
  color:#3f3f3f;
font-weight: bold;
;
} */
.table-bordered {
  border-bottom: 2px solid #e6e4e9 !important;
}
.card-news {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.table tr th {
  color: #3f3f3f;
  border-color: #e6e4e9;
}
/* .autocomplete-dropdown-container {

  position: absolute;
  background-color: none;

  z-index: 1000;
  font-size: 14px;
  font-weight: bold;
  color: #3f3f3f;
  font-family: chivoLight !important;
} */
/* html {
  --antd-wave-shadow-color:none;
  --scroll-bar: 0;
} */
.menu-link {
  font-family: chivoBold !important;
  color: #fff !important;
  text-decoration: none !important;
}
h5.font-weight-bold {
  color: #404040;
  font-size: 1.22rem;
}

.event-step {
  padding-left: 51px !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.select-limit.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: -2px !important;
}
.edit-event-tabs .fa {
  font-size: 15px !important;
  margin-right: 6px !important;
  margin-left: 7px !important;
}

span.tab-head {
  font-family: ChivoLight !important;
  font-weight: 600 !important;
  font-size: 12px;
}

.home-datepicker .ant-picker-suffix {
  display: none !important;
}
h3.widget-20-figure {
  font-family: chivoBold !important;
}
.ant-select-item-option-content {
  color: #8d8a89 !important;
  font-size: 0.8125rem;
  font-weight: 400;
}
.filter-icon {
  margin-right: 5px !important;
}
input.dropify.p-0 {
  display: block;
  border: 1px solid #e6e4e9;
}

.imagePreview:hover .overlay {
  opacity: 1;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.705);
}

.text {
  color: white;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.file-name {
  font-family: chivoBold !important;
  font-size: 15px !important;
}
.line::before {
  content: '';
  position: absolute;
  /* top: 0; */
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: #fff;
  width: 30px;
  height: 2px;
}

.upload_doc {
  outline: none !important;
  box-shadow: none !important;
}
button.dropify-clear {
  z-index: 9999 !important;
}
.dropify-wrapper:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(
    135deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  ) !important;
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}
@-webkit-keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
@keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}

.bootstrap-timepicker-widget table td span {
  border: solid #3f3f3f;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  width: 7px;
  cursor: pointer;
}

.bootstrap-timepicker-widget table td span.menu-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.bootstrap-timepicker-widget table td span.menu-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
}

.bootstrap-timepicker-widget table td input {
  border: none;
  color: #3f3f3f;
  font-weight: 400;
  background-color: #ffffff;
}
.btn-up-down:hover {
  background-color: #ececec;
  border: none;
  border-radius: 4px !important;
}

.ant-popover-inner {
  margin-top: 0;
  font-size: 0.875rem;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15);
  border-radius: 0.25rem;
  background-color: #ffffff;
}

.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}
.bootstrap-timepicker-widget table td input {
  width: 38px;
  margin: 0;
  height: 40px;
  text-align: center;
}

.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}
.event-date {
  text-transform: uppercase;
}
.placholder {
  color: #8f8c8b !important;
}
.ant-select.select-limit.mr-1.ml-1.ant-select-single.ant-select-show-arrow {
  position: relative;
}
.select-limit.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  top: 4px;
  font-family: chivoBold !important;
  columns: #000;
  height: 25px !important;
  padding: 0 11px;
}
.select-limit span.ant-select-arrow {
  margin-top: -2px !important;
  /* top: 17px; */
  color: #000;
  font-weight: bold;
  font-family: chivoBold;
}
.white {
  color: #fff !important;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 160px;
}
.react-tel-input .form-control {
  display: block;
  width: 100% !important;
  height: calc(1.5em + 1.0625rem + 2px) !important;
  color: #3f3f3f !important;
  background-color: #ffffff !important;
  background-clip: padding-box !important;
  border: 1px solid #e6e4e9 !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ef3e27 !important;
  font-weight: 500;
}
.autocomplete-dropdown-container {
  line-height: 1.5rem !important;
}
input.form-control {
  position: relative !important;
}

button.btn.btn-sm.btn-dark.dark-black {
  position: absolute;
  top: 33px;
}

input.form-control.price-input {
  position: relative !important;
  padding: 0.53125rem 1.5rem !important;
}
/* ...................svg icons..................................... */

.sidebar ul li a svg path {
  stroke: #fff;
}
.sidebar ul li a:hover svg circle,
.sidebar ul li a:hover svg path {
  stroke: #ef3e27;
}

.sidebar ul li a.active svg circle,
.sidebar ul li a.active svg path {
  stroke: #ef3e27;
}

.fa-user-headset:hover {
  width: 15px;
  fill: red;
  height: 15px;
}
svg.menu-icon {
  display: inline-block;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.75rem;
  width: 1rem;
  height: 1rem;
}

.svg-inline--fa.fa-w-20 {
  width: 1rem;
}
.svg-filter {
  margin-left: 10px;
  margin-right: 15px;
  width: 10px;
}
.svg-active {
  margin-left: 10px;
  margin-right: 15px;
  width: 13px;
}
td.btn-up-down {
  cursor: pointer;
}

.cropper-drag-box.cropper-crop.cropper-modal {
  top: -6px;
  height: 314px !important;
}
svg.svg-inline--fa.fa-trash-alt.fa-w-14 {
  cursor: pointer;
}
.modal-backdrop.show {
  opacity: 0.75 !important;
}
.string-check input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 5px;
  margin-top: 5px;
  z-index: 1;

  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
i.far.fa-image.x {
  font-size: 13px !important;
}
.cropper-bg {
  background-image: none !important;
}
button.cm_hide_upload {
  position: relative;
}

button.cm_hide_upload input {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.newsfeed img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 200px;
}

h5.card-title {
  text-transform: uppercase;
  font-weight: bold;
}

.autocomplete-dropdown-container {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  width: 100%;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.suggestion-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px !important;
  color: #8d8a89 !important;
  font-size: 0.8125rem;
  font-weight: 400;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.suggestion-item--active:hover {
  color: #ef3e27 !important;
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  background-color: #ced4da;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.ant-select-item-option-content:hover {
  color: #ef3e27 !important;
  font-size: 0.8125rem;
  font-weight: 400;
}

.autocomplete-dropdown-container :hover {
  background-color: #f5f5f5 !important;
}
.text-primary,
.task-list-wrapper .completed .remove {
  color: #ef3e27 !important;
}
i.fa.fa-chevron-left {
  position: absolute;
  margin-top: 3px;
  color: #ef3e27 !important;
}
i.fa.fa-chevron-right {
  position: absolute;
  margin-top: 3px;
  color: #ef3e27 !important;
}
.filled-success {
  fill: #00cb8e;
  font-size: 20px;
}
.event-list .event-item .event-schedule .event-icon {
  stroke-width: 1;
  width: 18px;
  font-size: 18px;
  height: 18px;
  margin: 0.6875rem 1rem 0 0;
}

.cm_col2 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 306px !important;
}
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: 0;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-editor.ql-blank::before {
  color: #0000006e !important;
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  font-size: 14px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.ql-container {
  color: #3f3f3f;
}
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: #ef3e27 !important;
}

label.checkbox-checkoutFields {
  color: #3f3f3f;
  font-size: 13px;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
}
.event-location.mt-5 {
  height: 600px !important;
}
.gm-style .gm-style-cc span,
.gm-style .gm-style-cc a,
.gm-style .gm-style-mtc div {
  font-size: 10px;
  box-sizing: border-box;
}
.fa-heart.mr-1 {
  cursor: pointer;
}
img.event-banner-img {
  width: 100%;
  height: 100%;
}
h2.text-uppercase.font-weight-bold.mb-0 {
  font-size: 2rem;
}
.break {
  word-break: break-all;
}
.prefix {
  font-size: 15px;

  left: 23px;
  top: 30px;
  position: absolute;
  z-index: 99;
}

[id='heart'] {
  position: absolute;
  left: -100vw;
}

[for='heart'] {
  color: #aab8c2;
  cursor: pointer;
  font-size: 1em;
  align-self: center;
  transition: color 0.2s ease-in-out;
}

[for='heart']:hover {
  color: grey;
}

[for='heart']::selection {
  color: none;
  background: transparent;
}

[for='heart']::moz-selection {
  color: none;
  background: transparent;
}

[id='heart']:checked + label {
  color: #e2264d;
  will-change: font-size;
  animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}

@keyframes heart {
  0%,
  17.5% {
    font-size: 0;
  }
}

span.action-link.pl-3.pr-3 {
  cursor: pointer;
}
i.fas.fa-paper-plane {
  color: #9e9e9e;
  font-size: 20px;
  position: absolute;
  bottom: 63px;
  right: 11px;
  /* transform: skewY(20deg); */
}

span.post-btn.mt-2 {
  color: #ef3e27 !important;
  cursor: pointer;
}

span.cancel-btn.mt-2 {
  color: #9e9e9e;
  cursor: pointer;
}

span.cancel-btn.mt-2:hover {
  color: #404040 !important;
}
button.btn.btn-link:hover {
  color: #404040 !important;
  cursor: pointer;
}
button.btn.btn-link {
  font-weight: 100 !important;
  color: #ef3e27 !important;
  text-decoration: none;
}

.navbar-home .navbar-nav > .nav-item a {
  padding: 0 30px;

  color: #808080;
  font-size: 14px;
}
a.btn.btn-block.btn-lg.btn-brand.pt-3.pb-3.pl-5.pr-5.font-weight-bold {
  background: #ef3e27 !important;
  font-size: 20px;
  border: none;
  text-decoration: none;
}
a.btn.btn-block.btn-lg.btn-brand.pt-3.pb-3.pl-5.pr-5.font-weight-bold:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
  font-size: 20px;
  color: #fff;

  border: none;
  text-decoration: none;
  border: red;
}
a.btn.btn-block.btn-brand.h-100.font-weight-bold.font-size-20:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
  color: #fff;
}
.dropdown-menu.cart-container.show {
  position: absolute;
  top: 0;
  transform: translate3d(0px, -26px, 0px);
  left: 0px;
  will-change: transform;
}

span#dropdownMenuButton {
  cursor: pointer;
}
span.footer-li {
  color: #aeaeae;
  text-decoration: none;
  line-height: 1.7;
}

span.footer-li:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
}

.upload_doc.imagePreview {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
}
.dropify-wrapper:hover {
  /* background-size: 30px 30px; */
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  ) !important;
  animation: stripes 2s linear infinite;
}
.upload_img {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  border: 1px solid #e6e4e9;
  height: 200px;
  text-align: center;
  border-radius: 5px;
  position: relative;
  outline: none;
  cursor: pointer;
}

.upload_img p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  word-break: break-all;
}

img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 314px;
}

.nesweed-crop img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 200px !important;
  border-radius: 5px !important;
}
.reply-list {
  list-style: none;
}

ul.reply-list.close {
  display: none;
}

span.text-brand.mr-3.font-size-11 {
  cursor: pointer;
}
span.text-brand.mr-2.font-size-11 {
  cursor: pointer;
}

input.form-control.create-account {
  padding: 12px;
  border: 1px solid #e6e4e9 !important;
  position: relative;
}
.eye_icon1 {
  position: absolute;
  width: 22px;
  top: 31px;
  right: 15px;
  z-index: 99;
  cursor: pointer;
}
.eye_icon1 svg {
  fill: #ced4da;
}

.eye_icon1.slesh:after {
  content: '';
  position: absolute;
  width: 26px;
  height: 3px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  transform: rotate(-45deg);
  border-radius: 3px;
}
.word-break {
  word-break: break-all;
}
.user-login.login-v2 .user-login-content {
  max-width: 600px;
  margin: auto;
}
.cm_alert_danger {
  color: red;
}
.alert-red {
  color: #852d35;
  background-color: #ffdde0;
  border-color: #ffd0d4;
}
.alert-green {
  color: #006a4a;
  background-color: #ccf5e8;
  border-color: #b8f0df;
}
.search {
  margin-left: 245px;
}
.cursorptr {
  cursor: pointer;
}
.search {
  margin-left: 165px;
  width: 100%;
  max-width: 400px;
}

.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}

.txtclr {
  color: red;
}
.faclr {
  color: #000;
}
.card-toolbar span.ant-select-selection-placeholder {
  opacity: 1;
  color: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.DayPicker-Day {
  padding: 0px !important;
  height: 30px !important;
  width: 30px !important;
  font-size: 13px !important;
  margin: 0px 2px !important;
  display: inline-block !important;
  line-height: 30px !important;
}
.DayPicker-Month {
  margin: 0 !important;
}
.DayPicker-wrapper {
  padding-bottom: 0 !important;
}
.DayPicker-WeekdaysRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.DayPicker-NavButton {
  top: 0px !important;
  right: 0 !important;
  width: 15px !important;
  height: 15px !important;
}
.DayPicker-Weekday {
  padding: 0 !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #ef3e27 !important;
}
.DayPicker-WeekdaysRow {
  display: flex !important;
  justify-content: space-between !important;
}

p.text-wrap.text-break.page-msg {
  width: 100%;
  background-color: #e9ecef;
  opacity: 1;
  padding: 0.53125rem 0.8125rem;
  border-radius: 0.25rem;
  border: 1px solid #e6e4e9;
  font-weight: 400;
  line-height: 1.5;
  color: #3f3f3f;
  font-size: 0.8125rem;
  max-height: 135px;
  display: inline-block;
  white-space: nowrap;
  overflow-y: auto;
  text-overflow: ellipsis;
}
